import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd September 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now easily get the request URI using `}<inlineCode parentName="li">{`RequestHeaders.uri()`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpRequest.uri()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2092"
        }}>{`#2092`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RequestHeaders headers = RequestHeaders.of(HttpMethod.GET, "/foo",
                                           HttpHeaderNames.SCHEME, "https",
                                           HttpHeaderNames.AUTHORITY, "example.com"));
assert headers.uri().equals(URI.create("https://example.com/foo"));
assert HttpRequest.of(header).uri() == headers.uri();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now decorate the `}<inlineCode parentName="li">{`Runnable`}</inlineCode>{` of the `}<inlineCode parentName="li">{`Thread`}</inlineCode>{` created using `}<inlineCode parentName="li">{`ThreadFactoryBuilder`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2047"
        }}>{`#2047`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2067"
        }}>{`#2067`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ThreadFactories.builder(...)
               .eventLoop(true)
               .daemon(true)
               .priority(...)
               .taskFunction(task -> {
                   // Specify your logic here so it's executed before the Runnable.
                   task.run();
                   // Specify your logic here so it's executed after the Runnable.
               })
               .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now configure `}<inlineCode parentName="li">{`Server`}</inlineCode>{` using `}<inlineCode parentName="li">{`Consumer<ServerBuilder>`}</inlineCode>{` as well as `}<inlineCode parentName="li">{`ArmeriaServerConfigurator`}</inlineCode>{` when integrating with Spring Boot. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2070"
        }}>{`#2070`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public Consumer<ServerBuilder> customizer() {
    return sb -> sb.port(...)
                   ...
                   .service(...);
}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now set example requests using `}<inlineCode parentName="li">{`AnnotatedServiceRegistrationBean`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1855"
        }}>{`#1855`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2026"
        }}>{`#2026`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public AnnotatedServiceRegistrationBean okService() {
    return new AnnotatedServiceRegistrationBean()
            .setServiceName("myAnnotatedService")
            .setPathPrefix("/my_service")
            .setService(new MyAnnotatedService())
            .setExampleRequests(Lists.of(
                    AnnotatedExampleRequest.of("myMethod", "{\\"foo\\":\\"bar\\"}")));
}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can be aware of whether the response content is empty from an HTTP status code. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2058"
        }}>{`#2058`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpStatus status = ...
if (!status.isContentAlwaysEmpty()) {
    // We may have a body!
}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can use `}<inlineCode parentName="li">{`SettableHealthIndicator`}</inlineCode>{` for health check responses when using Spring boot autoconfigure. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2088"
        }}>{`#2088`}</a></li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You will see the doc service automatically scrolls down to the debug form if the URL contains a request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1682"
        }}>{`#1682`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2045"
        }}>{`#2045`}</a></li>
      <li parentName="ul">{`The performance for parsing a struct context in Thrift is improved, thanks to the `}<a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/259"
        }}>{`Stack-Walking API`}</a>{` in Java 9. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1686"
        }}>{`#1686`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2055"
        }}>{`#2055`}</a></li>
      <li parentName="ul">{`You will see the int values of Enum members if they have in doc service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1966"
        }}>{`#1966`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2015"
        }}>{`#2015`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`RequestLog`}</inlineCode>{` is used for trace parsing and sampling. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2038"
        }}>{`#2038`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You can now get the gRPC web trailers in the client. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2030"
        }}>{`#2030`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2076"
        }}>{`#2076`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.baeldung.com/spring-boot-actuators"
        }}>{`actuator`}</a>{` now responds with the correct Spring boot content type. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2061"
        }}>{`#2061`}</a></li>
      <li parentName="ul">{`The blocking stub in gRPC now can read messages. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2065"
        }}>{`#2065`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2066"
        }}>{`#2066`}</a></li>
      <li parentName="ul">{`The listeners for `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` are notified after the first health check even when all endpoints are unhealthy. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2074"
        }}>{`#2074`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2075"
        }}>{`#2075`}</a></li>
      <li parentName="ul">{`The subscriber who subscribes to the `}<inlineCode parentName="li">{`Response`}</inlineCode>{` from a `}<inlineCode parentName="li">{`WebClient`}</inlineCode>{` gets notified when it's complete. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2080"
        }}>{`#2080`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2087"
        }}>{`#2087`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` that indicates the log should have at least one child is no longer raised when retrying. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2082"
        }}>{`#2082`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2083"
        }}>{`#2083`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DefaultEventLoopScheduler`}</inlineCode>{` respects `}<inlineCode parentName="li">{`maxNumEventLoopsPerHttpHttp1Endpoint`}</inlineCode>{` set from `}<inlineCode parentName="li">{`ClientFactoryBuilder`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2086"
        }}>{`#2086`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecation",
        "aria-label": "deprecation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecation`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`EventLoopThreadFactory`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`ThreadFactories`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2067"
        }}>{`#2067`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ThreadFactory factory = ThreadFactories.builder("myThread")
                                       .eventLoop(true)
                                       .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Specifying an `}<inlineCode parentName="p">{`:authority`}</inlineCode>{` header in a request has no effect anymore. The current `}<inlineCode parentName="p">{`Endpoint`}</inlineCode>{` always determines the authority. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2092"
          }}>{`#2092`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// This does NOT work anymore.
final HttpClient client = HttpClient.of("https://127.0.0.1:8080/");
client.execute(RequestHeaders.of(HttpMethod.GET, "/",
                                 HttpHeaderNames.AUTHORITY, "foo.com"));
// This works.
final HttpClient client =
        HttpClient.of(SessionProtocol.HTTPS,
                      Endpoint.of("foo.com", 8080).withIpAddr("127.0.0.1"));
client.get("/");
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Instead, you can now use `}<inlineCode parentName="p">{`ClientOption.HTTP_HEADERS`}</inlineCode>{` or `}<inlineCode parentName="p">{`{Client,Service}RequestContext.additional{Request,Response}{Headers,Trailers}()`}</inlineCode>{` to override the existing headers, including `}<inlineCode parentName="p">{`:authority`}</inlineCode>{`. `}<a parentName="p" {...{
                "href": "https://github.com/line/armeria/issues/2092"
              }}>{`#2092`}</a></p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// This works.
final HttpHeaders customHeaders =
        HttpHeaders.of(HttpHeaderNames.AUTHORITY, "foo.com");
final HttpClient client =
        HttpClient.of("http://127.0.0.1:8080/",
                      ClientOption.HTTP_HEADERS.newValue(customHeaders));
client.get("/");

// This also works.
final HttpClient client = HttpClient.of("http://127.0.0.1:8080/");
try (SafeCloseable ignored = Clients.withHttpHeader(
             HttpHeaderNames.AUTHORITY, "foo.com")) {
    client.get("/");
}
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ExceptionHandlerFunction.handleExeption()`}</inlineCode>{` accepts `}<inlineCode parentName="p">{`ServiceRequestContext`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`RequestContext`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2060"
          }}>{`#2060`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`You don't have to downcast anymore. :)`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`GrpcServiceRegistrationBean.ExampleRequest`}</inlineCode>{` has been removed. Use `}<inlineCode parentName="p">{`GrpcExampleRequest`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Micrometer 1.2.0 -> 1.2.1`}</li>
      <li parentName="ul">{`Netty 4.1.39.Final -> 4.1.41.Final`}</li>
      <li parentName="ul">{`Tomcat 9.0.24 -> 9.0.26`}
        <ul parentName="li">
          <li parentName="ul">{`Tomcat8 8.5.43 -> 8.5.45`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['andrewoma', 'anirudhr95', 'anuraaga', 'codefromthecrypt', 'eugene70', 'heowc', 'hirakida', 'ikhoon', 'imasahiro', 'jyblue', 'kojilin', 'minwoox', 'moonchanyong', 'SooJungDev', 'taejs', 'trustin', 'Waynefn']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      